import React, { forwardRef, useState, useCallback, useImperativeHandle } from "react";
import { Modal } from "reactstrap";
import ImageGallery from "react-image-gallery";
import Icon from "./Icon";

import "./FullscreenGalleria.css";

function ImageGalleryCloseButton(props) {
    return (
        <button className="image-gallery-icon image-gallery-close-button" {...props}>
            <Icon width={50} name="close-cross" className="image-gallery-svg" />
        </button>
    );
}

function FullscreenGalleria({ data }, ref) {
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [clickedImageIndex, setClickedImageIndex] = useState(0);

    const toggleGallery = useCallback(
        (src) => {
            let index = -1;
            if (src !== undefined) index = data.findIndex(({ original }) => original === src);
            setIsGalleryOpen(index >= 0);
            setClickedImageIndex(index < 0 ? clickedImageIndex : index);
        },
        [data, clickedImageIndex, setIsGalleryOpen, setClickedImageIndex]
    );

    useImperativeHandle(ref, () => ({ open: toggleGallery }));

    return (
        <Modal isOpen={isGalleryOpen} toggle={toggleGallery} backdrop="static" className="fullscreen-galleria">
            <ImageGallery
                items={data}
                lazyLoad={true}
                showBullets={true}
                infinite={true}
                showFullscreenButton={false}
                showPlayButton={false}
                showIndex={true}
                startIndex={clickedImageIndex}
                renderCustomControls={useCallback(
                    () => (
                        <ImageGalleryCloseButton onClick={toggleGallery} />
                    ),
                    [toggleGallery]
                )}
            />
        </Modal>
    );
}

export default forwardRef(FullscreenGalleria);
