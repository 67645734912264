import base64js from "base64-js";

export function Base64Encode(str, encoding = "utf-8") {
    if (!str) return str;
    var bytes = new (TextEncoder || TextEncoderLite)(encoding).encode(str);
    return base64js.fromByteArray(bytes);
}

export function Base64Decode(str, encoding = "utf-8") {
    if (!str) return str;
    var bytes = base64js.toByteArray(str);
    return new (TextDecoder || TextDecoderLite)(encoding).decode(bytes);
}
