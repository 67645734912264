import React from "react";
import { Button } from "reactstrap";
import "./ContrastButton.css";

export default function ContrastButton({ href, children }) {
    return (
        <Button color="dark" className="contrast-button" href={href}>
            <b>{children}</b>
        </Button>
    );
}
