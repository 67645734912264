import React from "react";
import { YMaps } from "react-yandex-maps";
import Layout from "./components/Layout";
import Cover from "./components/Cover";
import QuickInfo from "./components/QuickInfo";
import Services from "./components/Pricebook";
import Certificate from "./components/Certificate";
import Schedule from "./components/Schedule";
import Galleria from "./components/Galleria";
import AboutUs from "./components/AboutUs";
import Teachers from "./components/Teachers";
import Contacts from "./components/Contacts";
import Credits from "./components/Credits";

import "./App.css";

export default function App() {
    function scrollDownToStarting(e) {
        e.preventDefault();
        document.querySelector("#starting").scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }

    return (
        <YMaps>
            <Layout>
                <Cover onDownArrowClick={scrollDownToStarting} />
                <QuickInfo id="starting" />
                <Services id="services" />
                <Certificate id="certificate" />
                <Schedule id="schedule" />
                <Galleria id="galleria" />
                <div className="pt-5" id="about">
                    <AboutUs />
                </div>
                <Teachers id="teachers" />
                <div className="pt-5" id="contacts">
                    <Contacts />
                </div>
                <Credits id="credits" />
            </Layout>
        </YMaps>
    );
}
