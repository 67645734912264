import logo01 from "../images/credits/flugger.webp";
import logo02 from "../images/credits/urgeu.webp";
import logo03 from "../images/credits/mega.webp";
import logo04 from "../images/credits/robek.webp";
import logo05 from "../images/credits/laimalux.svg";

const creditsData = [
    {
        logo: logo01,
        title: "Краски",
        url: "https://flugger.ru/",
        sizeMult: 1,
    },
    {
        logo: logo02,
        title: "Уральский государственный экономический университет",
        url: "https://www.usue.ru/",
        sizeMult: 1.7,
    },
    {
        logo: logo03,
        title: "Торговый центр",
        url: "https://mega.ru/",
        sizeMult: 1,
    },
    {
        logo: logo04,
        title: "Обувь",
        url: "https://robek.ru/",
        sizeMult: 1,
    },
    {
        logo: logo05,
        title: "Красота и здоровье",
        url: "https://www.laimalux.com/",
        sizeMult: 1.05,
    },
];

export default creditsData;
