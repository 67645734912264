import React, { useRef, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import HotImage from "./HotImage";
import FullscreenGalleria from "./FullscreenGalleria";

import teachersData from "./teachers-data";

import "./Teachers.css";

const teachersGalleryData = teachersData.map(({ photo, thumbnail, name, description }) => ({
    original: photo,
    thumbnail: thumbnail,
    description: `${name} - ${description}`,
}));

export default function Teachers(props) {
    var fullscreenGalleriaRef = useRef(null);
    return (
        <div className="teachers" {...props}>
            <Container>
                <h1 className="text-break">Познакомьтесь с нами</h1>
                <p>
                    В нашей студии работают художники - профессионалы имеющие не только художественное, но и психологическое образование.
                    Для активной и плодотворной работы, важно учитывать возрастные особенности каждого участника. К каждому участнику
                    индивидуальный и профессиональный подход.
                </p>
                <Row>
                    {teachersData.map(({ name, description, photo, thumbnail }) => (
                        <TeacherPhoto
                            key={name}
                            name={name}
                            description={description}
                            photo={photo}
                            thumbnail={thumbnail}
                            fullscreenGalleriaRef={fullscreenGalleriaRef}
                        />
                    ))}
                </Row>
            </Container>
            <FullscreenGalleria ref={fullscreenGalleriaRef} data={teachersGalleryData} />
        </div>
    );
}

function TeacherPhoto({ name, description, photo, thumbnail, fullscreenGalleriaRef }) {
    const toggle = useCallback(() => fullscreenGalleriaRef.current.open(photo), [fullscreenGalleriaRef, photo]);
    return (
        <Col lg={4} sm={6} className="p-2">
            <Card onClick={toggle}>
                <HotImage src={thumbnail}>
                    <CardBody>
                        <CardTitle tag="h3">{name}</CardTitle>
                        <CardText>{description}</CardText>
                    </CardBody>
                </HotImage>
            </Card>
        </Col>
    );
}
