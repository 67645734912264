import { useState, useEffect, useLayoutEffect, useCallback, useRef } from "react";
import { findDOMNode } from "react-dom";
import JQuery from "jquery";

export function provideScrollFraction(wrappedComponent, { isRelativeToViewport } = { isRelativeToViewport: false }) {
    function ScrollFractionProviderInternal(props) {
        const [appearanceFraction, setAppearanceFraction] = useState(0);
        const nodeRef = useRef();

        const onWindowScroll = useCallback(() => {
            const node = findDOMNode(nodeRef.current);
            const rect = node.getBoundingClientRect();
            if (rect.width <= 0 || rect.height <= 0) {
                setAppearanceFraction(0);
                return;
            }
            const menuTop = JQuery(".sticky-top").height();
            let upper = menuTop - rect.height;
            const lower = window.innerHeight;
            let top = rect.top;
            if (top > lower) top = lower;
            else if (top < upper) top = upper;
            if (isRelativeToViewport) {
                upper = 0;
            }
            setAppearanceFraction((top - upper) / (lower - upper));
        }, [setAppearanceFraction]);

        useLayoutEffect(onWindowScroll, [onWindowScroll]);

        useEffect(() => {
            window.addEventListener("scroll", onWindowScroll);
            window.addEventListener("resize", onWindowScroll);
            return () => {
                window.removeEventListener("scroll", onWindowScroll);
                window.removeEventListener("resize", onWindowScroll);
            };
        });

        return wrappedComponent(props, nodeRef, appearanceFraction);
    }

    return ScrollFractionProviderInternal;
}
