import artStudio01 from "../images/galleria/art-studio/01.jpg";
import artStudio02 from "../images/galleria/art-studio/02.jpg";
import artStudio03 from "../images/galleria/art-studio/03.jpg";
import artStudio04 from "../images/galleria/art-studio/04.jpg";
import artStudio05 from "../images/galleria/art-studio/05.jpg";
import artStudio06 from "../images/galleria/art-studio/06.jpg";
import artStudio07 from "../images/galleria/art-studio/07.jpg";
import artStudio08 from "../images/galleria/art-studio/08.jpg";
import artStudio09 from "../images/galleria/art-studio/09.jpg";
import artStudio10 from "../images/galleria/art-studio/10.jpg";
import artStudio11 from "../images/galleria/art-studio/11.jpg";
import masterClass from "../images/galleria/art-studio/mc-full.jpeg";
import kids01 from "../images/galleria/kids/01.jpg";
import kids02 from "../images/galleria/kids/02.jpg";
import kids03 from "../images/galleria/kids/03.jpg";
import kids04 from "../images/galleria/kids/04.jpg";
import kids05 from "../images/galleria/kids/05.jpg";
import kids06 from "../images/galleria/kids/06.jpg";
import kids07 from "../images/galleria/kids/07.jpg";
import kids08 from "../images/galleria/kids/08.jpg";
import works01 from "../images/galleria/works/01.jpg";
import works02 from "../images/galleria/works/02.jpg";
import works03 from "../images/galleria/works/03.jpg";
import works04 from "../images/galleria/works/04.jpg";
import works05 from "../images/galleria/works/05.jpg";

const galleriaData = [
    {
        src: artStudio01,
        imageSize: [1000, 1500],
        width: 4,
        height: 6,
    },
    {
        src: artStudio02,
        imageSize: [1280, 960],
        width: 4,
        height: 3,
    },
    {
        src: masterClass,
    },
    {
        src: artStudio03,
        imageSize: [1280, 834],
        width: 4,
        height: 3,
    },
    {
        src: artStudio04,
    },
    {
        src: artStudio05,
    },
    {
        src: artStudio06,
    },
    {
        src: artStudio07,
    },
    {
        src: artStudio08,
    },
    {
        src: artStudio09,
    },
    {
        src: artStudio10,
    },
    {
        src: artStudio11,
    },
    {
        src: kids01,
        imageSize: [1680, 1260],
        width: 4,
        height: 3,
    },
    {
        src: kids02,
        imageSize: [1680, 1260],
        width: 4,
        height: 3,
    },
    {
        src: kids03,
        imageSize: [1680, 1259],
        width: 4,
        height: 3,
    },
    {
        src: kids04,
    },
    {
        src: kids05,
    },
    {
        src: kids06,
    },
    {
        src: kids07,
    },
    {
        src: kids08,
        imageSize: [500, 350],
        width: 4,
        height: 3,
    },
    {
        src: works01,
        imageSize: [645, 919],
        width: 4,
        height: 6,
    },
    {
        src: works02,
        imageSize: [1280, 853],
        width: 4,
        height: 3,
    },
    {
        src: works03,
        imageSize: [1280, 960],
        width: 4,
        height: 3,
    },
    {
        src: works04,
    },
    {
        src: works05,
    },
];

export default galleriaData;
