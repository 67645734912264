import React from "react";
import { Container, Row, Col } from "reactstrap";
import Icon from "./Icon";
import CSSOnScreenTransition from "./CSSOnScreenTransition";

import "./QuickInfo.css";

export default function QuickInfo(props) {
    return (
        <div className="quick-info alt-color" {...props}>
            <Container fluid>
                <Row>
                    <QuickInfoItem icon="location">ул.&nbsp;Новгородцевой&nbsp;17-Б</QuickInfoItem>
                    <div className="divisor" />
                    <QuickInfoItem icon="clock">
                        10:00&nbsp;–&nbsp;21:00
                        <br />
                        без&nbsp;выходных
                    </QuickInfoItem>
                    <div className="divisor" />
                    <QuickInfoItem icon="call" href="tel:+73432686790">
                        Телефон
                        <br />
                        +7&nbsp;(343)&nbsp;268&nbsp;67&nbsp;90
                    </QuickInfoItem>
                </Row>
            </Container>
        </div>
    );
}

function QuickInfoItem({ href, icon, children }) {
    return (
        <CSSOnScreenTransition appear useYInvert timeout={800} classNames="item">
            <Col sm="4" className="text-center py-5 text-dark" tag={href ? "a" : "div"} href={href}>
                <Icon name={icon} />
                {children}
            </Col>
        </CSSOnScreenTransition>
    );
}
