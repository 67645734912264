export const HttpCodes = Object.freeze({
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    UNUSED: 306,
    TEMPORARY_REDIRECT: 307,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    REQUEST_ENTITY_TOO_LARGE: 413,
    REQUEST_URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    REQUESTED_RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
});

export const HttpReasons = Object.freeze({
    CONTINUE: "Continue",
    SWITCHING_PROTOCOLS: "Switching Protocols",
    OK: "OK",
    CREATED: "Created",
    ACCEPTED: "Accepted",
    NON_AUTHORITATIVE_INFORMATION: "Non-Authoritative Information",
    NO_CONTENT: "No Content",
    RESET_CONTENT: "Reset Content",
    PARTIAL_CONTENT: "Partial Content",
    MULTIPLE_CHOICES: "Multiple Choices",
    MOVED_PERMANENTLY: "Moved Permanently",
    FOUND: "Found",
    SEE_OTHER: "See Other",
    NOT_MODIFIED: "Not Modified",
    USE_PROXY: "Use Proxy",
    UNUSED: "(Unused)",
    TEMPORARY_REDIRECT: "Temporary Redirect",
    BAD_REQUEST: "Bad Request",
    UNAUTHORIZED: "Unauthorized",
    PAYMENT_REQUIRED: "Payment Required",
    FORBIDDEN: "Forbidden",
    NOT_FOUND: "Not Found",
    METHOD_NOT_ALLOWED: "Method Not Allowed",
    NOT_ACCEPTABLE: "Not Acceptable",
    PROXY_AUTHENTICATION_REQUIRED: "Proxy Authentication Required",
    REQUEST_TIMEOUT: "Request Timeout",
    CONFLICT: "Conflict",
    GONE: "Gone",
    LENGTH_REQUIRED: "Length Required",
    PRECONDITION_FAILED: "Precondition Failed",
    REQUEST_ENTITY_TOO_LARGE: "Request Entity Too Large",
    REQUEST_URI_TOO_LONG: "Request-URI Too Long",
    UNSUPPORTED_MEDIA_TYPE: "Unsupported Media Type",
    REQUESTED_RANGE_NOT_SATISFIABLE: "Requested Range Not Satisfiable",
    EXPECTATION_FAILED: "Expectation Failed",
    INTERNAL_SERVER_ERROR: "Internal Server Error",
    NOT_IMPLEMENTED: "Not Implemented",
    BAD_GATEWAY: "Bad Gateway",
    SERVICE_UNAVAILABLE: "Service Unavailable",
    GATEWAY_TIMEOUT: "Gateway Timeout",
    HTTP_VERSION_NOT_SUPPORTED: "HTTP Version Not Supported",
});

export const HttpCodeToReason = Object.freeze({
    [HttpCodes.CONTINUE]: HttpReasons.CONTINUE,
    [HttpCodes.SWITCHING_PROTOCOLS]: HttpReasons.SWITCHING_PROTOCOLS,
    [HttpCodes.OK]: HttpReasons.OK,
    [HttpCodes.CREATED]: HttpReasons.CREATED,
    [HttpCodes.ACCEPTED]: HttpReasons.ACCEPTED,
    [HttpCodes.NON_AUTHORITATIVE_INFORMATION]: HttpReasons.NON_AUTHORITATIVE_INFORMATION,
    [HttpCodes.NO_CONTENT]: HttpReasons.NO_CONTENT,
    [HttpCodes.RESET_CONTENT]: HttpReasons.RESET_CONTENT,
    [HttpCodes.PARTIAL_CONTENT]: HttpReasons.PARTIAL_CONTENT,
    [HttpCodes.MULTIPLE_CHOICES]: HttpReasons.MULTIPLE_CHOICES,
    [HttpCodes.MOVED_PERMANENTLY]: HttpReasons.MOVED_PERMANENTLY,
    [HttpCodes.FOUND]: HttpReasons.FOUND,
    [HttpCodes.SEE_OTHER]: HttpReasons.SEE_OTHER,
    [HttpCodes.NOT_MODIFIED]: HttpReasons.NOT_MODIFIED,
    [HttpCodes.USE_PROXY]: HttpReasons.USE_PROXY,
    [HttpCodes.UNUSED]: HttpReasons.UNUSED,
    [HttpCodes.TEMPORARY_REDIRECT]: HttpReasons.TEMPORARY_REDIRECT,
    [HttpCodes.BAD_REQUEST]: HttpReasons.BAD_REQUEST,
    [HttpCodes.UNAUTHORIZED]: HttpReasons.UNAUTHORIZED,
    [HttpCodes.PAYMENT_REQUIRED]: HttpReasons.PAYMENT_REQUIRED,
    [HttpCodes.FORBIDDEN]: HttpReasons.FORBIDDEN,
    [HttpCodes.NOT_FOUND]: HttpReasons.NOT_FOUND,
    [HttpCodes.METHOD_NOT_ALLOWED]: HttpReasons.METHOD_NOT_ALLOWED,
    [HttpCodes.NOT_ACCEPTABLE]: HttpReasons.NOT_ACCEPTABLE,
    [HttpCodes.PROXY_AUTHENTICATION_REQUIRED]: HttpReasons.PROXY_AUTHENTICATION_REQUIRED,
    [HttpCodes.REQUEST_TIMEOUT]: HttpReasons.REQUEST_TIMEOUT,
    [HttpCodes.CONFLICT]: HttpReasons.CONFLICT,
    [HttpCodes.GONE]: HttpReasons.GONE,
    [HttpCodes.LENGTH_REQUIRED]: HttpReasons.LENGTH_REQUIRED,
    [HttpCodes.PRECONDITION_FAILED]: HttpReasons.PRECONDITION_FAILED,
    [HttpCodes.REQUEST_ENTITY_TOO_LARGE]: HttpReasons.REQUEST_ENTITY_TOO_LARGE,
    [HttpCodes.REQUEST_URI_TOO_LONG]: HttpReasons.REQUEST_URI_TOO_LONG,
    [HttpCodes.UNSUPPORTED_MEDIA_TYPE]: HttpReasons.UNSUPPORTED_MEDIA_TYPE,
    [HttpCodes.REQUESTED_RANGE_NOT_SATISFIABLE]: HttpReasons.REQUESTED_RANGE_NOT_SATISFIABLE,
    [HttpCodes.EXPECTATION_FAILED]: HttpReasons.EXPECTATION_FAILED,
    [HttpCodes.INTERNAL_SERVER_ERROR]: HttpReasons.INTERNAL_SERVER_ERROR,
    [HttpCodes.NOT_IMPLEMENTED]: HttpReasons.NOT_IMPLEMENTED,
    [HttpCodes.BAD_GATEWAY]: HttpReasons.BAD_GATEWAY,
    [HttpCodes.SERVICE_UNAVAILABLE]: HttpReasons.SERVICE_UNAVAILABLE,
    [HttpCodes.GATEWAY_TIMEOUT]: HttpReasons.GATEWAY_TIMEOUT,
    [HttpCodes.HTTP_VERSION_NOT_SUPPORTED]: HttpReasons.HTTP_VERSION_NOT_SUPPORTED,
});
