import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import ClassNames from "classnames";
import CSSOnScreenTransition from "./CSSOnScreenTransition";
import api from "../api/schedule-api";

import "./Schedule.css";

const dayAbbreviations = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
const mapDayOfWeek = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
};

function LessonRow({ name, days }) {
    return (
        <tr>
            <td>{name}</td>
            {days.map((x, i) => (
                <td key={i}>
                    {x && (
                        <React.Fragment>
                            {x.from}
                            <div className="times-separator" />
                            {x.to}
                        </React.Fragment>
                    )}
                </td>
            ))}
        </tr>
    );
}

function TableView({ lessons }) {
    return (
        <table className="table-view">
            <thead>
                <tr>
                    <th />
                    {dayAbbreviations.map(x => (
                        <th key={x}>{x}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {lessons.map(x => (
                    <LessonRow key={x.id} {...x} />
                ))}
            </tbody>
        </table>
    );
}

function NarrowLessonView({ name, days }) {
    return (
        <div className="lesson d-flex align-items-center align-items-lt-baseline flex-column flex-lt-row">
            <div className="name text-break flex-grow-1 mr-lt-2 w-100">{name}</div>
            <CSSOnScreenTransition appear timeout={500} classNames="entry">
                <div className="entry flex-shrink-0">
                    {days.map((x, i) => (
                        <Row key={i} noGutters className={ClassNames("day", { "d-none": !x })}>
                            {x && (
                                <React.Fragment>
                                    <Col xs="3" className="week-day-abbr">
                                        {dayAbbreviations[i]}
                                    </Col>
                                    <Col xs="9" className="times">
                                        {x.from}&nbsp;&mdash;&nbsp;{x.to}
                                    </Col>
                                </React.Fragment>
                            )}
                        </Row>
                    ))}
                </div>
            </CSSOnScreenTransition>
        </div>
    );
}

function NarrowView({ lessons }) {
    return (
        <Container className="py-0">
            {lessons.map(x => (
                <NarrowLessonView key={x.id} {...x} />
            ))}
        </Container>
    );
}

export default function Schedule(props) {
    const [lessons, setLessons] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const loadLessons = useCallback(async () => {
        setErrorMessage();
        setIsLoading(true);
        try {
            const data = await api.Get();
            for (const lesson of data.lessons) {
                lesson.days = [null, null, null, null, null, null, null];
                for (const [day, { from, to }] of Object.entries(lesson.entries)) {
                    lesson.days[mapDayOfWeek[day]] = {
                        from: from.toString(),
                        to: to.toString(),
                    };
                }
            }
            setLessons(data.lessons);
        } catch (e) {
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    }, [setLessons, setIsLoading, setErrorMessage]);

    useEffect(() => {
        loadLessons();
    }, [loadLessons]);

    return (
        <Container fluid="xl" className="schedule pb-0" {...props}>
            <h1 className="text-break">Расписание</h1>
            <div className="text-center">
                {isLoading && "Загружаем расписание..."}
                {errorMessage}
            </div>
            {!isLoading && (
                <React.Fragment>
                    <div className="d-none d-md-block">
                        <TableView lessons={lessons} />
                    </div>
                    <div className="d-block d-md-none">
                        <NarrowView lessons={lessons} />
                    </div>
                </React.Fragment>
            )}
        </Container>
    );
}
