import React, { useState, useContext } from "react";
import { Navbar, NavItem, NavLink, NavbarToggler, Collapse } from "reactstrap";
import Icon from "./Icon";
import BaseUrlContext from "./BaseUrlContext";
import "./NavMenu.css";

export default function NavMenu() {
    const baseUrl = useContext(BaseUrlContext);
    const [collapsed, setCollapsed] = useState(true);

    const resetCollapsed = setCollapsed.bind(true);

    function MyNavLink({ children, newWindow, ...props }) {
        return (
            <NavLink
                tag="a"
                className="px-2"
                onClick={resetCollapsed}
                target={newWindow ? "_blank" : null}
                rel={newWindow ? "noopener noreferrer" : null}
                {...props}>
                {children}
            </NavLink>
        );
    }

    return (
        <header className="sticky-top bg-white">
            <Navbar className="flex-column justify-content-center font-weight-bold" light>
                <div className="d-flex align-items-center mw-100">
                    <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
                    <a className="logo" href={baseUrl}>
                        <div />
                    </a>
                </div>
                <Collapse isOpen={!collapsed} className="left-panel" navbar>
                    <ul className="navbar-nav">
                        <NavItem>
                            <MyNavLink href="#about">О&nbsp;нас</MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink href="#services">Услуги</MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink href="#schedule">Расписание</MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink href="#galleria">Галерея</MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink href="#contacts">Контакты</MyNavLink>
                        </NavItem>
                    </ul>
                </Collapse>
                <Collapse isOpen={!collapsed} className="right-panel" navbar>
                    <ul className="navbar-nav">
                        <NavItem>
                            <MyNavLink newWindow href="https://wa.me/79041773777">
                                <Icon name="whatsapp" />
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink newWindow href="https://vk.com/artto4ka">
                                <Icon name="vkontakte" />
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink newWindow href="https://www.instagram.com/ART.STUDIO.TO44KA/">
                                <Icon name="instagram" />
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink className="phone" href="tel:+79041773777">
                                <Icon name="phone" />
                                <label>8&nbsp;(343)&nbsp;268&#x2011;67&#x2011;90</label>
                            </MyNavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
}
