import JQuery from "jquery";
import { HttpCodeToReason } from "../utils/http-codes";
import { Base64Decode } from "../utils/base64";

async function RunApiFunction(endpoint, method, operationDescription, data) {
    var result;
    try {
        result = await JQuery.ajax({
            method: method,
            url: endpoint,
            dataType: "json",
            contentType: "text/json",
            data: JSON.stringify(data),
        });
    } catch (e) {
        throw new Error(
            `Невозможно ${operationDescription}.\r\n${
                e.status && e.getResponseHeader
                    ? `Статус: ${e.status} ${HttpCodeToReason[e.status]}.\r\n${
                          Base64Decode(e.getResponseHeader("X-Exception-Message")) ?? ""
                      }`
                    : e.message
            }`
        );
    }
    if (result)
        if (result.success) return result;
        else if (result.message) throw new Error(`Невозможно ${operationDescription}.\r\nОшибка: ${result.message}`);
    throw new Error(`Невозможно ${operationDescription}. Ошибка при выполнении запроса.`);
}

export function ApiGet(endpoint, operationDescription) {
    return RunApiFunction(endpoint, "GET", operationDescription);
}
