import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import CSSOnScreenTransition from "./CSSOnScreenTransition";
import ContrastButton from "./ContrastButton";
import api from "../api/pricebook-api";
import "./Pricebook.css";

function PricebookItem({ duration, name, price }) {
    return (
        <Row noGutters>
            <CSSOnScreenTransition appear timeout={800} classNames="item">
                <Col lg="8" className="item mx-auto">
                    <p className="uptitle">{duration} мин.</p>
                    <Row>
                        <Col sm={true} className="heading">
                            {name}
                        </Col>
                        <Col xs="auto" className="price">
                            &#x20BD;{price}
                        </Col>
                    </Row>
                </Col>
            </CSSOnScreenTransition>
        </Row>
    );
}

export default function Pricebook(props) {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const loadItems = useCallback(async () => {
        setErrorMessage();
        setIsLoading(true);
        try {
            setItems((await api.Get()).items);
        } catch (e) {
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    }, [setItems, setIsLoading, setErrorMessage]);

    useEffect(() => {
        loadItems();
    }, [loadItems]);

    const whatsappLinkText = encodeURI("Здравствуйте, я хотел бы записаться в Вашу студию");
    return (
        <Container className="pricebook" {...props}>
            <h1>Наши услуги</h1>
            <h5>Приглашаем Вас в Нашу новую творческую студию!</h5>
            <div>
                {isLoading && "Загружаем элементы..."}
                {errorMessage}
            </div>
            {items.map(({ id, ...item }) => (
                <PricebookItem key={id} {...item} />
            ))}
            <ContrastButton href={`https://wa.me/79041773777?text=${whatsappLinkText}`}>Записаться</ContrastButton>
        </Container>
    );
}
