import React, { useCallback, useRef } from "react";
import { Container } from "reactstrap";
import Gallery from "react-photo-gallery";
import FullscreenGalleria from "./FullscreenGalleria";
import HotImage from "./HotImage";
import CSSOnScreenTransition from "./CSSOnScreenTransition";

import galleriaData from "./galleria-data";

import "./Galleria.css";

const imageGalleryData = galleriaData.map(({ src }) => ({ original: src, thumbnail: src }));
const pagePhotoGalleryData = galleriaData.filter(({ width, height }) => width > 0 && height > 0);

export default function Galleria(props) {
    const fullscreenGalleriaRef = useRef(null);

    const showFullscreen = useCallback((src) => fullscreenGalleriaRef.current.open(src), [fullscreenGalleriaRef]);

    const renderImage = useCallback(
        ({ margin, photo: { width, height, src }, left, top }) => (
            <div className="p-2" style={{ margin, left, top, width, height, position: "absolute" }} key={src}>
                <CSSOnScreenTransition appear useYInvert timeout={900} classNames="photo">
                    <HotImage className="photo" src={src} onClick={showFullscreen.bind(null, src)} transparentOnHover />
                </CSSOnScreenTransition>
            </div>
        ),
        [showFullscreen]
    );

    return (
        <Container className="galleria" {...props}>
            <h1 className="title">Галерея</h1>
            <Gallery photos={pagePhotoGalleryData} direction="column" margin={0} renderImage={renderImage} />
            <FullscreenGalleria ref={fullscreenGalleriaRef} data={imageGalleryData} />
        </Container>
    );
}
