import React from "react";
import ClassNames from "classnames";
import "./HotImage.css";

export default function HotImage({ src, className, children, transparentOnHover, ...otherProps }) {
    return (
        <div className={ClassNames("hot-image", className)} {...otherProps}>
            <div className={ClassNames({ "transparent-on-hover": transparentOnHover })} style={{ backgroundImage: `url(${src})` }}>
                {children}
            </div>
        </div>
    );
}
