import React from "react";
import { Container } from "reactstrap";

import creditsData from "./credits-data";

export default function Credits(props) {
    return (
        <Container className="credits text-center" {...props}>
            <h1 className="text-break">Нам доверяют:</h1>
            {creditsData.map(({ logo, title, url, sizeMult }) => (
                <a target="_blank" href={url} className="m-3 d-inline-block" rel="noopener noreferrer" key={url}>
                    <img src={logo} title={title} alt={title} style={{ height: `${3.5 * sizeMult}rem`, maxWidth: `calc(100vw - 4rem)` }} />
                </a>
            ))}
        </Container>
    );
}
