import React from "react";
import { provideScrollFraction } from "./ScrollFractionProvider";

export default provideScrollFraction(
    function ScrollActiveImage({ src, srcWidth, srcHeight, depth, ...props }, ref, appearanceFraction) {
        if (!depth) depth = 0;
        else if (depth < 0 || depth > 1) throw Error("Depth must be in range [0..1].");
        return (
            <div
                {...props}
                ref={ref}
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    backgroundPositionX: "50%",
                    backgroundPositionY: `${window.innerHeight * appearanceFraction * (1 - depth)}px`,
                }}
            />
        );
    },
    {
        isRelativeToViewport: true,
    }
);
