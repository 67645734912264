import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { provideScrollFraction } from "./ScrollFractionProvider";

export default provideScrollFraction(function CSSOnScreenTransition(
    { appear, timeout, useYInvert, classNames, children },
    ref,
    appearanceFraction
) {
    const isOnScreen = appearanceFraction > 0 && appearanceFraction < 1;
    const [isAbove, setIsAbove] = useState(false);
    if (isAbove) {
        if (appearanceFraction >= 1) setIsAbove(false);
    } else {
        if (appearanceFraction <= 0) setIsAbove(true);
    }
    return (
        <CSSTransition
            appear={appear}
            in={isOnScreen}
            timeout={isOnScreen ? timeout : 0}
            classNames={useYInvert && isAbove ? `${classNames}-y-invert` : classNames}
            ref={ref}
        >
            {children}
        </CSSTransition>
    );
});
