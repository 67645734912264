import React from "react";
import { Container, Row, Col } from "reactstrap";

import aboutPicture from "../images/about.webp";
import masterClassPicture from "../images/mc.webp";

import "./AboutUs.css";

export default function AboutUs(props) {
    return (
        <div className="about-us alt-color" {...props}>
            <Container>
                <Row noGutters>
                    <Col md={6} className="pr-md-4">
                        <h1 className="mt-0">О нас</h1>
                        <p>
                            В нашей студии мы предлагаем возможность познакомиться со всем многообразием изобразительного искусства,
                            материалов и техник. В студии вы познакомитесь как с классическими направлениями, так и с современным жанром в
                            изобразительном искусстве.
                        </p>
                        <h5>Наши направления:</h5>
                        <ul>
                            <li>Живопись, графика</li>
                            <li>Граффити</li>
                            <li>Комиксы</li>
                            <li>ТОЧКА KIDS для самых маленьких</li>
                        </ul>
                        <p>Рисовать в нашей студии может как самый маленький человек, так и взрослый!</p>
                        <p>
                            Группы формируются по возрасту. Главное - Вам выбрать направление, в котором мы будем вместе работать. И
                            помните: в каждом из нас заложены творческие способности природой, эти способности помогают нам мыслить, решать
                            задачи...
                        </p>
                    </Col>
                    <Col md={6} className="picture d-none d-md-block" style={{ backgroundImage: `url(${aboutPicture})` }} />
                </Row>
                <Row noGutters>
                    <Col md={6} className="picture" style={{ backgroundImage: `url(${masterClassPicture})` }} />
                    <Col md={6} className="pl-md-4 pt-4">
                        <h5>Также в нашей студии проходят различные мастер-классы по живописи:</h5>
                        <ul>
                            <li>
                                <strong>МК "Картина маслом!".</strong> Материалы: холст, масляные краски.
                            </li>
                            <li>
                                <strong>МК "Интерьерная картина".</strong> Материалы: холст, акрил, поталь (золотое напыление). Вы создадите
                                интерьерную картину своими руками и сможете или украсить свой дом или сделать замечательный подарок близким.
                            </li>
                            <li>
                                <strong>МК "Роспись одежды".</strong> Вы приносите свою футболку, джинсы, джинсовку или любой другой предмет
                                гардероба, который пожелаете украсить собственным рисунком. Преподаватель поможет перенести самые интересные
                                картинки на вашу одежду.
                            </li>
                            <li>
                                <strong>МК СО СВОБОДНОЙ ТЕМОЙ</strong>
                            </li>
                        </ul>
                        <h5 className="mt-5">Будем рады встрече с Вами в творческой обстановке!</h5>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
