const timeSpanRegex = /^(?<sign>-|\+)?((?<days>\d+)\.)?(?<hours>\d?\d):(?<minutes>\d?\d):(?<seconds>\d?\d(\.\d+)?)$/;

export default class TimeSpan {
    #totalSeconds = 0;

    constructor(...dhmsOrHms) {
        this.change(...dhmsOrHms);
    }

    static parse(timeSpanString) {
        const timeSpan = this.tryParse(timeSpanString);
        if (!timeSpan) throw new Error(`Cannot parse TimeSpan string: ${timeSpanString}`);
        return timeSpan;
    }

    static tryParse(timeSpanString) {
        const match = timeSpanRegex.exec(timeSpanString);
        if (!match) return null;

        var d = 0,
            h = 0,
            m = 0,
            s = 0;
        if (match.groups.days) d = Number(match.groups.days);
        if (match.groups.hours) h = Number(match.groups.hours);
        if (match.groups.minutes) m = Number(match.groups.minutes);
        if (match.groups.seconds) s = Number(match.groups.seconds);
        const timeSpan = new TimeSpan(d, h, m, s);

        if (match.groups.sign === "-") timeSpan.negate();

        return timeSpan;
    }

    toString() {
        const d = this.days;
        var h = this.hours;
        var m = this.minutes;
        var s = Math.abs(this.#totalSeconds % 60); // include fraction if any

        h = h < 10 ? `0${h}` : h;
        m = m < 10 ? `0${m}` : m;
        var result = `${h}:${m}`;

        if (s != 0) {
            s = s < 10 ? `0${s}` : s;
            result = `${result}:${s}`;
        }

        if (d != 0) result = `${d}.${result}`;
        if (this.isNegative) result = `-${result}`;

        return result;
    }

    change(...dhmsOrHms) {
        if (dhmsOrHms.length > 4) throw new Error("Expected number of parameters - not more than 4.");
        if (dhmsOrHms.length == 0) return;

        var d, h, m, s;
        if (dhmsOrHms.length == 4) {
            d = dhmsOrHms[0] || 0;
            h = dhmsOrHms[1] || 0;
            m = dhmsOrHms[2] || 0;
            s = dhmsOrHms[3] || 0;
        } else {
            d = 0;
            h = dhmsOrHms[0] || 0;
            m = dhmsOrHms[1] || 0;
            s = dhmsOrHms[2] || 0;
        }
        if (typeof d !== "number" || !isFinite(d) || d % 1 != 0) throw new Error("Days must be an integer number (can be negative).");
        if (typeof h !== "number" || !isFinite(h) || h < 0 || h % 1 != 0 || h > 23)
            throw new Error("Hours must be integer number in range [0...23].");
        if (typeof m !== "number" || !isFinite(m) || m < 0 || m % 1 != 0 || m > 59)
            throw new Error("Minutes must be integer number in range [0...59].");
        if (typeof s !== "number" || !isFinite(s) || s < 0 || s >= 60) throw new Error("Seconds must be a number in range [0...60).");
        this.#totalSeconds = d * 86400 + h * 3600 + m * 60 + s;
    }

    get days() {
        return Math.abs(Math.trunc(this.totalDays));
    }

    get hours() {
        return Math.abs(Math.trunc(this.totalHours % 24));
    }

    get minutes() {
        return Math.abs(Math.trunc(this.totalMinutes % 60));
    }

    get seconds() {
        return Math.abs(Math.trunc(this.#totalSeconds % 60));
    }

    get fraction() {
        return Math.abs(this.#totalSeconds % 1);
    }

    get isNegative() {
        return this.#totalSeconds < 0;
    }

    negate() {
        this.#totalSeconds = -this.#totalSeconds;
    }

    get totalSeconds() {
        return this.#totalSeconds;
    }

    get totalMinutes() {
        return this.#totalSeconds / 60;
    }

    get totalHours() {
        return this.#totalSeconds / 3600;
    }

    get totalDays() {
        return this.#totalSeconds / 86400;
    }

    set totalSeconds(x) {
        if (typeof x !== "number" || !isFinite(x)) throw new Error("Total seconds must be a finite number.");
        this.#totalSeconds = x;
    }

    set totalMinutes(x) {
        if (typeof x !== "number" || !isFinite(x)) throw new Error("Total minutes must be a finite number.");
        this.#totalSeconds = x * 60;
    }

    set totalHours(x) {
        if (typeof x !== "number" || !isFinite(x)) throw new Error("Total hours must be a finite number.");
        this.#totalSeconds = x * 3600;
    }

    set totalDays(x) {
        if (typeof x !== "number" || !isFinite(x)) throw new Error("Total days must be a finite number.");
        this.#totalSeconds = x * 86400;
    }
}
