import React from "react";
import Icon from "./Icon";
import CSSOnScreenTransition from "./CSSOnScreenTransition";

import "./Cover.css";

export default function Cover({ onDownArrowClick, ...props }) {
    return (
        <CSSOnScreenTransition appear timeout={700} classNames="cover">
            <div className="cover" {...props}>
                <div className="cover-gradient d-flex flex-column justify-content-center align-items-center">
                    <CSSOnScreenTransition appear timeout={1000} classNames="cover-title">
                        <h1 className="cover-title">
                            Центр развития творческих
                            <br />
                            способностей
                        </h1>
                    </CSSOnScreenTransition>
                    <CSSOnScreenTransition appear timeout={1300} classNames="cover-description">
                        <h4 className="cover-description">
                            г.&nbsp;Екатеринбург, ул.&nbsp;Новгородцевой&nbsp;17&#x2011;Б
                            <br />
                            <a className="text-decoration-none text-light" href="tel:+73432686790">
                                (343)&nbsp;268&nbsp;67&nbsp;90
                            </a>
                        </h4>
                    </CSSOnScreenTransition>
                    <CSSOnScreenTransition appear timeout={1600} classNames="cover-arrow">
                        <div className="cover-arrow" onClick={onDownArrowClick}>
                            <Icon name="angle-arrow-down" width="39px" />
                        </div>
                    </CSSOnScreenTransition>
                </div>
            </div>
        </CSSOnScreenTransition>
    );
}
