import photo01small from "../images/teachers/01.webp";
import photo02small from "../images/teachers/02.webp";
import photo03small from "../images/teachers/03.webp";
import photo04small from "../images/teachers/04.webp";
import photo05small from "../images/teachers/05.webp";
import photo06small from "../images/teachers/06.webp";
import photo01 from "../images/teachers/01-full.webp";
import photo02 from "../images/teachers/02-full.webp";
import photo05 from "../images/teachers/05-full.webp";
import photo06 from "../images/teachers/06-full.webp";

const teachersData = [
    {
        photo: photo01,
        thumbnail: photo01small,
        name: "Свечников Алексей",
        description: "Дизайнер, художник, психолог",
    },
    {
        photo: photo02,
        thumbnail: photo02small,
        name: "Горбут Алексей",
        description: "Ведущий художник, педагог",
    },
    {
        photo: photo03small,
        thumbnail: photo03small,
        name: "Шакарова Екатерина",
        description: "Художник, психолог, педагог",
    },
    {
        photo: photo04small,
        thumbnail: photo04small,
        name: "Токарева Юлия Александровна",
        description: "Доктор психологических наук, профессор",
    },
    {
        photo: photo05,
        thumbnail: photo05small,
        name: "Щипачева Ольга",
        description: "Педагог",
    },
    /*    {
        photo: photo06,
        thumbnail: photo06small,
        name: "Юлия Валиуллина",
        description: "Преподаватель по живописи",
    },*/
];

export default teachersData;
