import React from "react";
import { Map, Placemark } from "react-yandex-maps";
import Icon from "./Icon";

import "./Contacts.css";

export default function Contacts(props) {
    return (
        <div className="contacts d-flex flex-lg-row flex-column w-100" {...props}>
            <div className="flex-grow-1">
                <h1 className="mt-5">Как нас найти</h1>
                <h2 className="mb-0">
                    <a href="tel:+73432686790">
                        <b>+7&nbsp;(343) 268&nbsp;67&nbsp;90</b>
                    </a>
                </h2>
                <h2 className="mt-3">
                    <a href="mailto:studio-to4ka@mail.ru">
                        <b>studio-to4ka@mail.ru</b>
                    </a>
                </h2>
                <h6>
                    ул.&nbsp;Новгородцевой 17-Б
                    <br />
                    г.&nbsp;Екатеринбург
                </h6>
                <h2 className="mb-5">
                    <a target="_blank" rel="noopener noreferrer" href="https://vk.com/artto4ka">
                        <Icon name="vkontakte-circle" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/ART.STUDIO.TO44KA/">
                        <Icon name="instagram-circle" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/79041773777">
                        <Icon name="whatsapp-circle" />
                    </a>
                </h2>
            </div>
            <div className="map">
                <Map
                    className="w-100 h-100"
                    modules={["control.ZoomControl", "control.FullscreenControl", "control.TypeSelector"]}
                    defaultState={{
                        center: [56.831497, 60.677981],
                        zoom: 16,
                        controls: ["zoomControl", "fullscreenControl", "typeSelector"],
                    }}>
                    <Placemark
                        modules={["geoObject.addon.balloon"]}
                        defaultGeometry={[56.831497, 60.677981]}
                        properties={{ balloonContentBody: "ул. Новгородцевой 17-Б" }}
                    />
                </Map>
            </div>
        </div>
    );
}
