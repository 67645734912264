import { ApiGet } from "./run-api-function";
import TimeSpan from "../utils/time-span";

export default {
    Get: async () => {
        const result = (await ApiGet("/schedule/get", "получить расписание")).data;
        if (Array.isArray(result.lessons))
            for (const lesson of result.lessons)
                for (const day in lesson.entries) {
                    const entry = lesson.entries[day];
                    entry.from = TimeSpan.tryParse(entry.from) ?? entry.from ?? "error";
                    entry.to = TimeSpan.tryParse(entry.to) ?? entry.to ?? "error";
                }
        return result;
    },
};
