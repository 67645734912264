import React from "react";
import { Container } from "reactstrap";
import ContrastButton from "./ContrastButton";
import ScrollActiveImage from "./ScrollActiveImage";

import "./Certificate.css";
import certificateSvg from "../images/certificate.svg";
import certificateBack from "../images/certificate-back.webp";

export default function Certificate(props) {
    const whatsappLinkText = encodeURI("Здравствуйте, я хотел бы приобрести подарочный сертификат");
    return (
        <div className="text-center" {...props}>
            <Container className="py-0">
                <h5>Дарите своим близким возможность узнать больше об искусстве и раскрыть свой творческий потенциал!</h5>
            </Container>
            <div className="certificate">
                <ScrollActiveImage className="background" src={certificateBack} srcWidth={1920} srcHeight={1034} depth={0.5} />
                <img className="front" src={certificateSvg} alt="Подарочный сертификат" />
            </div>
            <ContrastButton href={`https://wa.me/79041773777?text=${whatsappLinkText}`}>Приобрести</ContrastButton>
        </div>
    );
}
